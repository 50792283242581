<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoad"></div>
        <div id="loader" v-if="isLoad"></div>
        <div
            class="box h-100 align-items-center cursor-pointer"
            :class="{ selected: type === 'monthly' }"
            @click="type = 'monthly'"
        >
            <label class="container-radio">
                <div class="d-flex flex-column">
                    <h5 class="fw-bold mb-0">Set Fix Target Bulanan</h5>
                    <p class="text-gray mb-0">
                        Tetapkan target tetap yang berlaku untuk setiap bulan
                        guna menciptakan stabilitas dan fokus yang
                        berkesinambungan.
                    </p>
                </div>
                <input
                    type="radio"
                    name="radio"
                    v-model="type"
                    value="monthly"
                />
                <span class="checkmark"></span>
            </label>
            <hr v-if="type === 'monthly'" />
            <div class="row" v-if="type === 'monthly'">
                <div class="col-12">
                    <label
                        >Nominal Target Bulanan
                        <span class="text-yellow">*</span></label
                    >
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="box h-100 align-items-center mt-3 cursor-pointer"
            :class="{ selected: type === 'quartal' }"
            @click="type = 'quartal'"
        >
            <label class="container-radio">
                <div class="d-flex flex-column">
                    <h5 class="fw-bold mb-0">
                        Set Target Bulanan Sesuai Kuartal
                    </h5>
                    <p class="text-gray mb-0">
                        Tetapkan target berdasarkan periode kuartal (target
                        Kuartal 1 otomatis diterapkan pada bulan Januari,
                        Februari, & Maret)
                    </p>
                </div>
                <input
                    type="radio"
                    name="radio"
                    v-model="type"
                    value="quartal"
                />
                <span class="checkmark"></span>
            </label>
            <hr v-if="type === 'quartal'" />
            <div class="row" v-if="type === 'quartal'">
                <div class="col-lg-6 my-3">
                    <label>Target Kuartal 1</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                    <p class="mb-0 mt-2 size-12">
                        Target ini akan diterapkan untuk bulan Januari, Februari
                        & Maret
                    </p>
                </div>
                <div class="col-lg-6 my-3">
                    <label>Target Kuartal 2 </label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                    <p class="mb-0 mt-2 size-12">
                        Target ini akan diterapkan untuk bulan April, Mei & Juni
                    </p>
                </div>
                <div class="col-lg-6 my-3">
                    <label>Target Kuartal 3 </label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                    <p class="mb-0 mt-2 size-12">
                        Target ini akan diterapkan untuk bulan Juli, Agustus &
                        September
                    </p>
                </div>
                <div class="col-lg-6 my-3">
                    <label>Target Kuartal 4 </label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                    <p class="mb-0 mt-2 size-12">
                        Target ini akan diterapkan untuk bulan Oktober, November
                        & Desember
                    </p>
                </div>
            </div>
        </div>
        <div
            class="box h-100 align-items-center mt-3 cursor-pointer"
            :class="{ selected: type === 'specific' }"
            @click="type = 'specific'"
        >
            <label class="container-radio">
                <div class="d-flex flex-column">
                    <h5 class="fw-bold mb-0">Set Target Bulan Spesifik</h5>
                    <p class="text-gray mb-0">
                        Tetapkan target sesuai kebutuhan spesifik setiap bulan
                        untuk menghadapi tantangan atau peluang tertentu.
                    </p>
                </div>
                <input
                    type="radio"
                    name="radio"
                    v-model="type"
                    value="specific"
                />
                <span class="checkmark"></span>
            </label>
            <hr v-if="type === 'specific'" />
            <div class="row" v-if="type === 'specific'">
                <div class="col-12">
                    <label
                        >Tahun Berlaku <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        :options="validYears"
                        label="name"
                        track-by="id"
                        placeholder="Pilih Tahun Target Berlaku"
                        class="multi-form-custom mt-2"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
            </div>
            <hr v-if="type === 'specific'" />
            <div class="row" v-if="type === 'specific'">
                <div class="col-lg-4 my-3">
                    <label>Target Januari</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target Februari</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target Maret</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target April</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target Mei</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target Juni</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target Juli</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target Agustus</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target September</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target Oktober</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target November</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
                <div class="col-lg-4 my-3">
                    <label>Target Desember</label>
                    <div class="input-group mb-0 mt-2">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nominal target"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
            <button class="btn btn-primary px-5 py-2">Simpan</button>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
// import Api from "../api/Api";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            type: "monthly",
            validYears: [
                {
                    id: 2025,
                    name: 2025,
                },
                {
                    id: 2026,
                    name: 2026,
                },
                {
                    id: 2027,
                    name: 2027,
                },
            ],
        };
    },
    created() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
hr {
    color: #c1cdd7;
}
.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0%;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #a3b4c2;
    transform: translate(0%, -50%);
}

.container-radio input:checked ~ .checkmark {
    background-color: transparent;
    border: 2px solid #329a5f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-radio input:checked ~ .checkmark:after {
    display: block;
}

.container-radio .checkmark:after {
    top: 2.6px;
    left: 2.6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #329a5f;
}

.box.selected {
    border: 1px solid #329a5f;
}
</style>
