<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="box-manifest">
            <div class="mb-3">
                <h5 class="fw-bold">Daftar Deal Order</h5>
            </div>
            <div class="row gy-3 mt-3">
                <div class="col-12 col-lg-5">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama customer/Deal ID"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span
                            class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                        >
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2">
                    <button
                        class="btn btn-outline-yellow btn-filter transparent w-100"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="container-fluid">
                    <div class="row gy-3">
                        <div
                            class="col-12 col-lg-4"
                            v-show="isRole(managerCSARoles)"
                        >
                            <label class="fw-bold mb-2">CRM Specialist</label>
                            <multiselect
                                v-model="specialist"
                                :options="specialists"
                                :show-labels="false"
                                label="name"
                                track-by="id"
                                placeholder="Pilih CRM specialist"
                                class="multi-form-custom"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-4">
                            <label class="fw-bold mb-2">Kategori</label>
                            <multiselect
                                v-model="category"
                                :options="categories"
                                label="name"
                                track-by="id"
                                placeholder="Pilih kategori"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-lg-4">
                            <label class="fw-bold mb-2">Tanggal</label>
                            <DatePicker
                                v-model="date"
                                placeholder-text="Pilih tanggal deal order"
                            />
                        </div>
                        <div class="col-12 col-lg-4">
                            <label
                                class="fw-bold mb-2 invisible"
                                v-if="!isRole(managerRoles)"
                                >Tanggal</label
                            >
                            <div class="d-flex flex-column flex-lg-row gap-2">
                                <button
                                    class="btn btn-outline-yellow flex-grow-1"
                                    @click="clearFilter()"
                                    :disabled="
                                        category == null &&
                                        specialist == null &&
                                        date == null
                                    "
                                >
                                    Clear
                                </button>
                                <button
                                    class="btn btn-green flex-grow-1"
                                    :disabled="
                                        category == null &&
                                        specialist == null &&
                                        date == null
                                    "
                                    @click="filterData()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex flex-column flex-lg-row align-items-lg-center gap-3 mt-3"
            >
                <div class="text-center text-lg-start">
                    Menampilkan
                    <span class="fw-bold">{{ pageStart }}-{{ pageEnd }}</span>
                    Data dari
                    <span class="fw-bold">{{ list.total_data }}</span>
                    Data
                </div>
                <div class="ms-lg-auto">
                    <div
                        class="d-flex align-items-center gap-2 justify-content-center"
                    >
                        <label for="pageSize" class="mb-0"
                            >Baris per halaman</label
                        >
                        <select
                            id="pageSize"
                            class="form-control form-select w-select outline-yellow"
                            style="width: auto; min-width: 80px"
                            v-model="limit"
                            @change="onLimitChange()"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_list">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    <div class="d-flex align-items-center">
                                        Tanggal Deal Order

                                        <div class="ms-auto">
                                            <a
                                                href="javascript:void(0)"
                                                class="dropdown-toggle ms-2"
                                                id="statusRefund"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="true"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src="../assets/dashboard/sort.png"
                                                    width="9"
                                                    alt=""
                                                />
                                            </a>
                                            <ul
                                                class="dropdown-menu"
                                                aria-labelledby="statusRefund"
                                            >
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('asc')"
                                                    >
                                                        Terbaru
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('desc')"
                                                    >
                                                        Terlama
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th class="text-center" scope="col">Deal ID</th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Kategori
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(managerCSARoles)"
                                >
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">
                                    Estimasi Berat Barang
                                </th>
                                <th class="text-center" scope="col">Rute</th>
                                <th class="text-center" scope="col">
                                    Harga Pengiriman
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Driver
                                </th>
                                <th class="text-center" scope="col">
                                    Status Penjemputan
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="
                                        tier == 'platinum' ||
                                        (tier == 'enterprise' &&
                                            isRole(requestJemput))
                                    "
                                >
                                    Request Jemput
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in list.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{ item.created_at }}
                                </td>
                                <td>
                                    {{ item.receipt ? item.receipt : "-" }}
                                </td>
                                <td class="capitalize">
                                    {{ item.customer_name }}
                                </td>
                                <td>
                                    <span
                                        class="tag mb-0 pb-0 capitalize"
                                        v-bind:class="{
                                            'blue-cro':
                                                item.category == 'new_order',
                                            'green-cro':
                                                item.category == 'repeat_order',
                                        }"
                                    >
                                        {{ item.category.split("_").join(" ") }}
                                    </span>
                                </td>
                                <td
                                    class="capitalize"
                                    v-show="isRole(managerCSARoles)"
                                >
                                    {{ item.specialist }}
                                </td>
                                <td class="text-center">
                                    {{ item.est_weight }} Kg
                                </td>
                                <td>
                                    {{ item.route }}
                                </td>
                                <td>
                                    <span class="pull-right">
                                        {{ currency(item.price) }}
                                    </span>
                                </td>
                                <td>
                                    {{
                                        item.driver_name
                                            ? item.driver_name
                                            : "-"
                                    }}
                                </td>
                                <td class="d-flex">
                                    <span
                                        class="mb-0 pb-0 capitalize rounded-pill text-center w-100"
                                        v-bind:class="{
                                            waiting:
                                                item.status_label ===
                                                'Menunggu Driver',
                                            'in-pick-up':
                                                item.status_label ===
                                                'Dalam Perjalanan',
                                            'off-loading':
                                                item.status_label ===
                                                'Menunggu Bongkar Muat',
                                            done:
                                                item.status_label === 'Selesai',
                                        }"
                                    >
                                        {{
                                            item.status_label
                                                ? item.status_label
                                                : "-"
                                        }}
                                    </span>
                                </td>
                                <td
                                    class="text-center"
                                    v-if="
                                        tier == 'platinum' ||
                                        (tier == 'enterprise' &&
                                            isRole(requestJemput))
                                    "
                                >
                                    <a
                                        href="javascript:void(0)"
                                        @click="openModalRequest(item)"
                                        v-if="item.status == false"
                                    >
                                        <img
                                            src="../assets/request.png"
                                            width="20"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        class="cursor-drop"
                                        v-else
                                    >
                                        <img
                                            src="../assets/request-gray.png"
                                            width="20"
                                            alt=""
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="list.list_data.length == 0" class="text-center mt-5">
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar Deal Order
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <b-pagination-nav
                            v-if="list.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage()"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalRequest"></div>
            <div class="modal-body-chat sm" v-if="modalRequest">
                <div class="">
                    <h5 class="mb-0 fw-bold">Request Penjemputan Orderan</h5>
                    <div class="mt-3">
                        <label class="fw-bold mb-1">
                            Unit Armada
                            <span class="text-danger">*</span>
                        </label>
                        <multiselect
                            v-model="fleet"
                            :options="fleets"
                            label="name"
                            track-by="code"
                            placeholder="Pilih unit armada"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="mt-2">
                        <label class="fw-bold mb-1">
                            Catatan untuk Driver
                            <span class="text-danger">*</span>
                        </label>
                        <textarea
                            cols="30"
                            rows="4"
                            class="form-control"
                            placeholder="Tulis catatan"
                            v-model="note"
                        ></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalRequest = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="
                                is_request || fleet == null || note == ''
                            "
                            @click="storeRequest()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_request"
                            ></span>
                            Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Request Penjemputan Berhasil
                    </h5>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalSuccess = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
import DatePicker from "../components/DatePicker.vue";

export default {
    components: {
        Multiselect,
        ListLoader,
        DatePicker,
    },
    name: "DealOrder",
    data() {
        return {
            pageStart: 0,
            pageEnd: 0,
            moment: moment,
            search: "",
            isFilter: false,
            specialist: null,
            specialists: [],
            category: null,
            categories: [
                {
                    name: "New Order",
                    id: "new",
                },
                {
                    name: "Repeat Order",
                    id: "repeat",
                },
            ],
            managerRoles: ["crm-manager"],
            managerCSARoles: ["crm-manager", "customer-service-analyst"],
            requestJemput: [
                "crm-specialist-telemarketer",
                "crm-specialist-sales",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
            ],
            list: {
                list_data: [],
                total_data: 0,
            },
            currentPage: 1,
            totalPage: 0,
            limit: 5,
            is_load_list: false,
            sort_at: "asc",
            modalRequest: false,
            modalSuccess: false,
            fleet: null,
            fleets: [],
            is_request: false,
            requst_id: null,
            note: "",
            tier: localStorage.getItem("tier"),
            user: null,
            roles: null,
            date: null,
        };
    },
    created() {
        this.getSpecialists();
        this.getLists();
        this.getFleets();
    },
    methods: {
        onLimitChange() {
            this.currentPage = 1;
            this.getLists();
        },
        filterData() {
            this.currentPage = 1;
            this.getLists();
        },
        clearFilter() {
            this.currentPage = 1;
            this.date = null;
            this.specialist = null;
            this.category = null;
            this.getLists();
        },
        changePage() {
            this.getLists();
        },
        getLists() {
            this.user = JSON.parse(localStorage.getItem("user"));
            this.roles = JSON.parse(localStorage.getItem("roles"));
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            const isNotManagerRole = !this.roles.some((role) =>
                this.managerCSARoles.includes(role)
            );
            if (isNotManagerRole) {
                payload.specialist_id = this.user?.id;
            } else if (this.specialist) {
                payload.specialist_id = this.specialist?.id;
            }
            if (this.sort_at) {
                payload.sort_at = this.sort_at;
            }
            if (this.category) {
                payload.category = this.category?.id;
            }
            if (this.date) {
                const localDate = new Date(this.date).toLocaleDateString(
                    "id-ID"
                );
                payload.date = localDate.split("/").reverse().join("-");
            }
            this.is_load_list = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/result/deal/lists`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.list = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.list.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                    this.is_load_list = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_list = false;
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sortBy(value) {
            this.sort_at = value;
            this.getLists();
        },
        getFleets() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleets = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        openModalRequest(item) {
            this.requst_id = item.id;
            this.modalRequest = true;
            this.note = "";
            this.fleet = null;
        },
        storeRequest() {
            this.is_request = true;
            var data = {
                result_id: this.requst_id,
                fleet: this.fleet?.code,
                note: this.note,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/result/deal/pickup`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalRequest = false;
                    this.modalSuccess = true;
                    this.is_request = false;
                    this.getLists();
                })
                .catch((err) => {
                    this.$toast.error(err.response.data.message, {
                        position: "top-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                    this.is_request = false;
                    this.modalRequest = false;
                });
        },
        searchData() {
            this.getLists();
            this.currentPage = 0;
        },
    },
};
</script>

<style scoped>
.waiting {
    background-color: #faf8d1;
    color: #267347;
    border: 1px solid #f1eb74;
}
.in-pick-up {
    background-color: #cce7ff;
    color: #005299;
    border: 1px solid #99d0ff;
}
.off-loading {
    background-color: #f5d6d6;
    color: #7b1e1e;
    border: 1px solid #ebadad;
}
.done {
    background-color: #d9f2e4;
    color: #267347;
    border: 1px solid #b2e6c8;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.btn-filter.transparent {
    background: transparent !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f7f7f7 !important;
    --bs-table-border-color: #f7f7f7 !important;
    --bs-table-bg: #f7f7f7 !important;
}
.mt-filter {
    margin-top: 25px;
}
</style>
