<template>
    <div class="padding-container">
        <div class="content-load" v-if="detailLoading"></div>
        <div id="loader" v-if="detailLoading"></div>
        <div class="fw-bold">
            <router-link to="/walk-in" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </router-link>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Data Customer</h5>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1"> Nama Customer </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama pengirim"
                        v-model="senderName"
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Pengirim
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor pengirim"
                            @input="validatePhoneSender"
                            v-model="senderPhone"
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1"> Kota/Kabupaten Asal </label>
                    <multiselect
                        v-model="originRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1"> Kota/Kabupaten Tujuan </label>
                    <multiselect
                        v-model="destRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten tujuan"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <div class="row">
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-1"> CRM Specialist</label>
                    <multiselect
                        v-model="specialist"
                        :options="specialists"
                        label="name"
                        track-by="id"
                        placeholder="Pilih CRM specialist"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 my-2" ref="serviceTypeField">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="serviceType"
                        :options="serviceTypes"
                        label="name"
                        track-by="id"
                        placeholder="Pilih jenis layanan"
                        :class="[
                            'multi-form-custom',
                            {
                                'multi-red-custom':
                                    showValidation && !serviceType,
                            },
                        ]"
                        :show-labels="false"
                    >
                    </multiselect>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="showValidation && !serviceType"
                    >
                        <img
                            src="../assets/warning-form.svg"
                            alt="warning form"
                        />
                        <p class="text-red mb-0 ms-2 size-12">
                            Mohon lengkapi data jenis layanan
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType?.id === 'pengiriman_barang'"
        >
            <h5 class="fw-bold mb-0">Daftar Barang</h5>
            <Goods
                ref="goodsComponent"
                :defaultData="defaultGoodsData"
                @update:data="handleGoodsData"
                :showValidation="showValidation"
            />
            <div class="mt-4 d-flex justify-content-end">
                <button
                    class="btn btn-primary px-5"
                    @click="modalSuccessConfirmation = true"
                >
                    Simpan Perubahan
                </button>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType?.id === 'pengiriman_motor'"
        >
            <h5 class="fw-bold mb-0">Detail Pengiriman Motor</h5>
            <Bike
                ref="bikesComponent"
                :defaultData="defaultBikeData"
                @update:data="handleBikesData"
                :showValidation="showValidation"
            />
            <div class="mt-4 d-flex justify-content-end">
                <button
                    class="btn btn-primary px-5"
                    @click="modalSuccessConfirmation = true"
                >
                    Simpan Perubahan
                </button>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType?.id === 'pengiriman_mobil'"
        >
            <h5 class="fw-bold mb-0">Detail Pengiriman Mobil</h5>
            <Car :defaultData="defaultCarData" @update:data="handleCarsData" />
            <div class="mt-4 d-flex justify-content-end">
                <button
                    class="btn btn-primary px-5"
                    @click="modalSuccessConfirmation = true"
                >
                    Simpan Perubahan
                </button>
            </div>
        </div>
        <div
            class="mt-4 d-flex justify-content-end"
            v-if="
                serviceType &&
                (serviceType.id === 'sewa_container_20ft' ||
                    serviceType.id === 'sewa_container_40ft')
            "
        >
            <button
                class="btn btn-primary px-5"
                @click="modalSuccessConfirmation = true"
            >
                Simpan Perubahan
            </button>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Akan Dibuat</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin membuat pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isSave"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSave"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil Dibuat</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/walk-in"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import Goods from "../components/ServiceType/Goods.vue";
import Bike from "../components/ServiceType/Bike.vue";
import Car from "../components/ServiceType/Car.vue";
import Api from "../api/Api";

export default {
    components: {
        Multiselect,
        Goods,
        Bike,
        Car,
    },
    data() {
        return {
            detailLoading: false,
            senderName: "",
            senderPhone: "",
            originRegency: null,
            destRegency: null,
            regencies: [],
            goodsData: null,
            bikesData: null,
            carsData: null,
            modalSuccessConfirmation: false,
            modalSuccess: false,
            isSave: false,
            defaultGoodsData: null,
            defaultBikeData: null,
            defaultCarData: null,
            specialist: null,
            specialists: [],
            serviceType: null,
            serviceTypes: [
                {
                    id: "pengiriman_barang",
                    name: "Pengiriman Barang",
                },
                {
                    id: "pengiriman_motor",
                    name: "Pengiriman Motor",
                },
                {
                    id: "pengiriman_mobil",
                    name: "Pengiriman Mobil",
                },
                {
                    id: "sewa_container_20ft",
                    name: "Sewa Container 20 Feet",
                },
                {
                    id: "sewa_container_40ft",
                    name: "Sewa Container 40 Feet",
                },
            ],
            showValidation: false,
        };
    },
    async created() {
        this.detailLoading = true;
        await this.getRegency();
        await this.getSpecialists();
        await this.getOrderDetail();
    },
    methods: {
        async getSpecialists() {
            return Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        handleGoodsData(data) {
            this.goodsData = data;
        },
        handleBikesData(data) {
            this.bikesData = data;
        },
        handleCarsData(data) {
            this.carsData = data;
        },
        async getOrderDetail() {
            const orderId = this.$route.query.order_id;
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/order/walkin/detail?order_id=${orderId}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    const data = res?.data?.data;
                    this.senderName = data?.sender_name;
                    this.senderPhone = data?.sender_phone;
                    this.originRegency = this.regencies.find(
                        (r) => r.id === data?.origin_regency_id
                    );
                    this.destRegency = data?.dest_regency_id
                        ? this.regencies.find(
                              (r) => r.id === data?.dest_regency_id
                          )
                        : null;
                    this.specialist = data?.specialist
                        ? this.specialists.find(
                              (r) => r.id === data?.specialist_id
                          )
                        : null;
                    this.serviceType = data?.service_type
                        ? this.serviceTypes.find(
                              (r) => r.id === data?.service_type
                          )
                        : null;
                    const listGoods =
                        data?.items?.length > 0
                            ? data.items.map((item) => ({
                                  qty: item.qty,
                                  description: item.description,
                                  weight: item.weight,
                                  length: item.length,
                                  width: item.width,
                                  height: item.height,
                                  protection:
                                      item.packing?.length > 0
                                          ? item.packing.map((h) => ({
                                                id: h.packing,
                                                name: h.packing
                                                    .replace(/_/g, " ")
                                                    .replace(/\b\w/g, (char) =>
                                                        char.toUpperCase()
                                                    ),
                                                price: h.price,
                                            }))
                                          : [],
                              }))
                            : null;

                    this.defaultGoodsData = {
                        items: listGoods || [
                            {
                                qty: "",
                                description: "",
                                weight: "",
                                length: "",
                                width: "",
                                height: "",
                                protection: [],
                            },
                        ],
                        note: data?.note || "",
                    };
                    if (data?.service_type === "pengiriman_motor") {
                        this.defaultBikeData = {
                            vehicle: data?.vehicle || "",
                            cc: data?.cc
                                ? {
                                      id: data.cc,
                                      name: this.getBikeCCName(data.cc),
                                  }
                                : null,
                            packings: data?.protections,
                            description: data?.note || "",
                        };
                    }
                    if (data?.service_type === "pengiriman_mobil") {
                        this.defaultCarData = {
                            vehicle: data?.vehicle || "",
                            description: data?.note || "",
                        };
                    }
                    this.detailLoading = false;
                })
                .catch((err) => {
                    this.detailLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getBikeCCName(cc) {
            const ccMapping = {
                lower: "100 CC - 149 CC",
                middle: "150 CC - 249 CC",
                high: "Diatas 250 CC",
            };
            return ccMapping[cc] || "";
        },
        validatePhoneSender() {
            if (!this.senderPhone.startsWith("8")) {
                this.senderPhone =
                    "8" + this.senderPhone.replace(/[^0-9]/g, "");
            } else {
                this.senderPhone = this.senderPhone.replace(/[^0-9]/g, "");
            }
        },
        async getRegency() {
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        storeData() {
            this.isSave = true;
            this.showValidation = true;

            const emptyFields = [
                {
                    field: this.$refs.serviceTypeField,
                    value: !this.serviceType,
                },
                ...(this.serviceType.id === "pengiriman_barang"
                    ? [
                          {
                              field: this.$refs.goodsComponent?.getFirstEmptyField(),
                              value: this.goodsData?.items?.some(
                                  (item) =>
                                      !item.description ||
                                      !item.weight ||
                                      !item.length ||
                                      !item.width ||
                                      !item.height
                              ),
                          },
                      ]
                    : []),
                ...(this.serviceType.id === "pengiriman_motor"
                    ? [
                          {
                              field: this.$refs.bikesComponent?.getFirstEmptyField(),
                              value:
                                  !this.bikesData?.vehicle ||
                                  !this.bikesData?.cc,
                          },
                      ]
                    : []),
            ];

            const firstEmptyField = emptyFields.find((item) => item.value);

            var data = {
                order_id: this.$route.query.order_id,
                sender_name: this.senderName,
                sender_phone: this.senderPhone,
                origin_regency_id: this.originRegency?.id,
                destination_regency_id: this.destRegency?.id,
                specialist: this.specialist?.id,
                service_type: this.serviceType?.id,
            };

            if (this.serviceType?.id === "pengiriman_barang") {
                const itemsData = this.goodsData?.items?.map((item) => ({
                    qty: item.qty,
                    description: item.description,
                    weight: item.weight,
                    length: item.length,
                    width: item.width,
                    height: item.height,
                    weight_volume: item.volumeWeight,
                    cubic: item.cubic,
                    protection:
                        item.protection.length > 0
                            ? item.protection.map((h) => ({
                                  packing: h.id,
                                  price: Math.round(h.price),
                              }))
                            : null,
                }));

                const packingPrice = this.goodsData?.items?.reduce(
                    (total, item) => {
                        if (item.protection && item.protection.length > 0) {
                            return (
                                total +
                                item.protection.reduce(
                                    (sum, p) => sum + Math.round(p.price),
                                    0
                                )
                            );
                        }
                        return total;
                    },
                    0
                );
                data.items = itemsData;
                data.charge_weight = this.goodsData?.chargeWeight;
                data.packing_price = packingPrice;
                data.note = this.goodsData?.note;
            }

            if (this.serviceType?.id === "pengiriman_motor") {
                data.vehicle = this.bikesData?.vehicle;
                data.cc = this.bikesData?.cc;
                data.note = this.bikesData?.description;
                data.protections =
                    this.bikesData?.protections.length > 0
                        ? this.bikesData?.protections.map((h) => ({
                              protection: h.packing,
                          }))
                        : null;
                data.packing_price = 0;
                data.charge_weight = 0;
            }

            if (this.serviceType?.id === "pengiriman_mobil") {
                data.vehicle = this.carsData?.vehicle;
                data.note = this.carsData?.description;
                data.packing_price = 0;
                data.charge_weight = 0;
            }

            if (
                this.serviceType?.id === "sewa_container_20ft" ||
                this.serviceType?.id === "sewa_container_40ft"
            ) {
                data.packing_price = 0;
                data.charge_weight = 0;
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/walkin/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isSave = false;
                    this.modalSuccess = true;
                    this.modalSuccessConfirmation = false;
                })
                .catch((err) => {
                    if (firstEmptyField) {
                        firstEmptyField?.field?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                        this.isSave = false;
                        this.modalSuccessConfirmation = false;
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                        return;
                    }
                    this.isSave = false;
                    this.modalSuccessConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .multi-form-custom.multi-red-custom .multiselect__tags {
    border: 1px solid #fe1e00 !important;
}
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}

.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
.w-430 {
    width: 430px;
}
textarea {
    resize: none;
}
hr.modal-hr {
    color: #c1cdd7 !important;
    margin: 0;
}
.modal-vue .modal-body-chat.vsm {
    width: 450px;
}
.btn-add {
    color: #40bf76 !important;
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.w-30 {
    width: 30px;
}
.w-120 {
    width: 120px;
}
.table > :not(caption) > * > * {
    border: unset;
}
.table-bordered > :not(caption) > * {
    border: unset;
}
.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
}
.w-300 {
    width: 300px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
div::-webkit-scrollbar {
    display: none;
}
.form-edit {
    border: unset !important;
    text-align: right;
    padding: 0px;
    font-weight: 600 !important;
    color: #000 !important;
    border-radius: unset !important;
}
.form-edit.yellow {
    color: #ff8600 !important;
}
</style>
