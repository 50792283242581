<template>
    <div>
        <div class="row">
            <div class="mt-3 col-lg-6" ref="bikeTypeField">
                <label class="mb-2"
                    >Tipe Motor <span class="text-yellow">*</span></label
                >
                <input
                    type="text"
                    class="form-control"
                    v-model="bikeType"
                    @input="handleTypeSearch()"
                    @focus="handleFocus()"
                    placeholder="Masukkan tipe motor"
                />
                <div class="suggestions-container" v-if="showSuggestions">
                    <p class="suggestion-title">
                        Pilih atau Tambahkan Tipe Motor
                    </p>
                    <div class="suggestion-item-container">
                        <div
                            class="suggestion-item-none"
                            v-if="typeSuggestions.length === 0"
                        >
                            <i>Tipe motor tidak ditemukan</i>
                        </div>
                        <div
                            v-else
                            v-for="(suggestion, index) in typeSuggestions"
                            :key="index"
                            class="suggestion-item"
                            @click="selectType(suggestion)"
                        >
                            {{ suggestion.name }}
                        </div>
                    </div>
                    <hr class="hr-suggestion" />
                    <p class="suggestion-title d-flex align-items-center">
                        <span>Tambahkan:</span>
                        <span
                            v-if="isAddVehicle"
                            class="spinner-border text-primary ms-2"
                            role="status"
                        ></span>
                        <span
                            v-else
                            class="text-suggestion-dark of-y pe-3 ps-1 cursor-pointer"
                            @click="createNewVehicle()"
                            >{{ bikeType }}</span
                        >
                    </p>
                </div>
                <div
                    class="d-flex align-items-center mt-2"
                    v-if="showValidation && !bikeType"
                >
                    <img
                        src="../../assets/warning-form.svg"
                        alt="warning form"
                    />
                    <p class="text-red mb-0 ms-2 size-12">
                        Mohon lengkapi data tipe motor
                    </p>
                </div>
            </div>
            <div class="col-lg-6 mt-3" ref="bikeCapacityField">
                <label class="mb-2"
                    >Kapasitas Motor <span class="text-yellow">*</span></label
                >
                <multiselect
                    v-model="bikeCapacity"
                    :options="bikeCapacities"
                    label="name"
                    track-by="id"
                    placeholder="Pilih kapasitas motor"
                    class="multi-form-custom"
                    :show-labels="false"
                ></multiselect>
                <div
                    class="d-flex align-items-center mt-2"
                    v-if="showValidation && !bikeCapacity"
                >
                    <img
                        src="../../assets/warning-form.svg"
                        alt="warning form"
                    />
                    <p class="text-red mb-0 ms-2 size-12">
                        Mohon lengkapi data kapasitas motor
                    </p>
                </div>
            </div>
            <div class="col-12 mt-3">
                <label class="mb-2">Perlindungan Barang</label>
                <div
                    class="form-box-white cursor-pointer"
                    :class="{ empty: selectedProtections.length === 0 }"
                    @click="toggleProtectionList"
                    ref="protectionBox"
                >
                    <div v-if="selectedProtections.length === 0">
                        Masukkan Perlindungan Barang
                    </div>
                    <div v-else>
                        {{ selectedProtectionsText }}
                    </div>
                </div>
                <div class="position-relative mt-2">
                    <div
                        class="position-absolute box-protection"
                        v-if="showProtectionList"
                        ref="protectionList"
                    >
                        <div
                            v-for="(protection, index) in protections"
                            :key="index"
                        >
                            <div class="d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    :id="protection.id"
                                    :value="protection.id"
                                    v-model="selectedProtections"
                                    class="me-2 custom-checkbox cursor-pointer"
                                />
                                <label
                                    :for="protection.id"
                                    class="cursor-pointer"
                                    >{{ protection.name }}</label
                                >
                            </div>
                            <hr v-if="index < protections.length - 1" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 mt-3">
                <label class="mb-2">Deskripsi</label>
                <textarea
                    class="form-control"
                    placeholder="Masukkan deskripsi untuk motor"
                    v-model="bikeDescription"
                ></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    props: {
        defaultData: {
            type: Object,
            default: () => null,
        },
        showValidation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            bikeType: "",
            showSuggestions: false,
            typeSuggestions: [],
            isAddVehicle: false,
            bikeCapacity: null,
            selectedProtections: [],
            showProtectionList: false,
            bikeCapacities: [
                {
                    name: "100 CC - 149 CC",
                    id: "lower",
                },
                {
                    name: "150 CC - 249 CC",
                    id: "middle",
                },
                {
                    name: "Diatas 250 CC",
                    id: "high",
                },
            ],
            bikeDescription: "",
            protections: [
                {
                    name: "Wrapping Plastic",
                    id: "wrapping_plastic",
                },
                {
                    name: "Pallet",
                    id: "pallet",
                },
                {
                    name: "Bubble Wrap",
                    id: "bubble_wrap",
                },
                {
                    name: "Kardus",
                    id: "kardus",
                },
                {
                    name: "Kayu",
                    id: "kayu",
                },
                {
                    name: "Karung Kecil",
                    id: "karung_kecil",
                },
                {
                    name: "Karung Sedang",
                    id: "karung_sedang",
                },
                {
                    name: "Karung Besar",
                    id: "karung_besar",
                },
            ],
        };
    },
    computed: {
        formData() {
            const protections = this.selectedProtections.map((id) => ({
                packing: id,
                price: 0,
            }));

            const data = {
                vehicle: this.bikeType,
                cc: this.bikeCapacity?.id,
                description: this.bikeDescription,
                protections: protections,
            };
            return data;
        },
        selectedProtectionsText() {
            return this.selectedProtections
                .map((id) => {
                    const protection = this.protections.find(
                        (p) => p.id === id
                    );
                    return protection ? protection.name : "";
                })
                .filter((name) => name)
                .join(", ");
        },
    },
    created() {
        document.addEventListener("click", this.handleClickOutside);
        document.addEventListener("click", (e) => {
            const suggestionsContainer = document.querySelector(
                ".suggestions-container"
            );
            const typeInput = document.querySelector(
                'input[placeholder="Masukkan tipe motor"]'
            );

            if (suggestionsContainer && typeInput) {
                if (
                    !suggestionsContainer.contains(e.target) &&
                    !typeInput.contains(e.target)
                ) {
                    this.showSuggestions = false;
                }
            }
        });

        if (this.defaultData) {
            this.bikeType = this.defaultData.vehicle || "";
            this.bikeCapacity = this.defaultData.cc
                ? this.bikeCapacities.find(
                      (r) => r.id === this.defaultData.cc.id
                  )
                : null;
            this.bikeDescription = this.defaultData.description || "";
            this.selectedProtections = this.defaultData.packings
                ? this.defaultData.packings.map((p) => p.protection)
                : [];
        }
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        getFirstEmptyField() {
            if (!this.bikeType) {
                return this.$refs.bikeTypeField;
            }
            if (!this.bikeCapacity) {
                return this.$refs.bikeCapacityField;
            }
            return null;
        },
        toggleProtectionList() {
            this.showProtectionList = !this.showProtectionList;
        },
        handleClickOutside(event) {
            if (
                this.$refs.protectionBox &&
                this.$refs.protectionList &&
                !this.$refs.protectionBox.contains(event.target) &&
                !this.$refs.protectionList.contains(event.target)
            ) {
                this.showProtectionList = false;
            }
        },
        emitDataChanges() {
            this.$emit("update:data", this.formData);
        },
        handleTypeSearch() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.getListVehicle();
            }, 300);
        },
        handleFocus() {
            this.handleTypeSearch();
            this.showSuggestions = true;
        },
        createNewVehicle() {
            this.isAddVehicle = true;
            const payload = {
                name: this.bikeType,
                type: "bike",
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vehicles/create`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isAddVehicle = false;
                    this.handleTypeSearch();
                })
                .catch((err) => {
                    this.isAddVehicle = false;
                    this.showSuggestions = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getListVehicle() {
            const payload = {
                search: this.bikeType,
                type: "bike",
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vehicles`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.typeSuggestions = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        selectType(suggestion) {
            this.bikeType = suggestion.name;
            this.showSuggestions = false;
        },
    },
    watch: {
        bikeType: {
            handler() {
                this.emitDataChanges();
            },
        },
        bikeCapacity: {
            handler() {
                this.emitDataChanges();
            },
        },
        bikeDescription: {
            handler() {
                this.emitDataChanges();
            },
        },
        selectedProtections: {
            handler() {
                this.emitDataChanges();
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.box-protection {
    border: 1px solid #d1d0d4;
    background-color: white;
    border-radius: 7px;
    padding: 20px 25px;
    z-index: 1;
}
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
.form-box-white {
    border: 1px solid #d1d0d4;
    background: white;
    border-radius: 7px;
    padding: 0.375rem 0.75rem;
    width: 100%;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #495057;
}
.form-box-white.empty {
    color: #acaab1;
}
.of-y::-webkit-scrollbar {
    display: none;
}
hr.hr-suggestion {
    margin: 0;
    margin-top: 5px;
}
.suggestions-container {
    position: static;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #c1cdd7;
    border-radius: 7px;
    margin-top: 2px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.suggestion-title {
    padding: 8px 12px !important;
    margin-bottom: 0 !important;
    font-size: 12px;
    color: #a3b4c2;
}
.text-suggestion-dark {
    color: #212529;
    font-weight: 500;
}
.suggestion-item-container {
    max-height: 200px;
    overflow-y: auto;
}
.suggestion-item {
    padding: 5px 24px;
    cursor: pointer;

    &:hover {
        background-color: #f3f5f7;
    }
}
.suggestion-item-none {
    padding: 5px 24px;
}
.hr-suggestion {
    margin: 0;
    margin-top: 5px;
}
textarea {
    resize: none;
}
</style>
