<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoad"></div>
        <div id="loader" v-if="isLoad"></div>
        <div class="box-manifest">
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="fw-bold">Target per Bulan</h5>
                <router-link to="/set-target/create" class="text-primary"
                    ><i class="fa fa-pencil size-16 me-2"></i
                    ><u>Tambah Target</u></router-link
                >
            </div>
            <hr />
            <div class="d-flex justify-content-center my-5">
                <div class="d-flex flex-column align-items-center">
                    <img
                        src="../assets/target-global.svg"
                        alt="target global"
                        width="100"
                    />
                    <h5 class="text-center text-primary mt-2">Rp 0</h5>
                </div>
            </div>
        </div>
        <div class="box-manifest">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <h5 class="fw-bold">Target Fix Bulanan</h5>
                    <p class="mb-0 text-gray">
                        Target bulanan akan ditampilkan secara otomatis setiap
                        bulan pada dashboard manager.
                    </p>
                </div>
                <router-link to="/set-target/create" class="text-primary"
                    ><i class="fa fa-cog size-16 me-2"></i
                    ><u>Atur Ulang Target</u></router-link
                >
            </div>
            <hr />
            <div class="d-flex justify-content-center my-5">
                <div class="d-flex flex-column align-items-center">
                    <img
                        src="../assets/target-global.svg"
                        alt="target global"
                        width="100"
                    />
                    <h5 class="text-center text-primary mt-2">Rp 0</h5>
                </div>
            </div>
        </div>
        <div class="box-manifest">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <h5 class="fw-bold">Target Bulanan Sesuai Kuartal</h5>
                    <p class="mb-0 text-gray">
                        Target kuartal akan ditampilkan secara otomatis pada
                        dashboard berdasarkan bulan dalam kuartal aktif.
                    </p>
                </div>
                <router-link to="/set-target/create" class="text-primary"
                    ><i class="fa fa-cog size-16 me-2"></i
                    ><u>Atur Ulang Target</u></router-link
                >
            </div>
            <hr />
            <div class="row">
                <div class="col-lg-6">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <img
                                src="../assets/target-global.svg"
                                alt="target global"
                                width="100"
                            />
                            <p class="text-primary text-center mt-2 mb-0">
                                Quartal 1
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                            <p class="text-gray mt-2 mb-0 text-center">
                                (Januari, Februari & Maret)
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <img
                                src="../assets/target-global.svg"
                                alt="target global"
                                width="100"
                            />
                            <p class="text-primary text-center mt-2 mb-0">
                                Quartal 2
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                            <p class="text-gray mt-2 mb-0 text-center">
                                (April, Mei & Juni)
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <img
                                src="../assets/target-global.svg"
                                alt="target global"
                                width="100"
                            />
                            <p class="text-primary text-center mt-2 mb-0">
                                Quartal 3
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                            <p class="text-gray mt-2 mb-0 text-center">
                                (Juli, Agustus & September)
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <img
                                src="../assets/target-global.svg"
                                alt="target global"
                                width="100"
                            />
                            <p class="text-primary text-center mt-2 mb-0">
                                Quartal 4
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                            <p class="text-gray mt-2 mb-0 text-center">
                                (Oktober, November & Desember)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-manifest">
            <div class="d-flex align-items-center justify-content-between">
                <div>
                    <h5 class="fw-bold">Target Bulan Spesifik</h5>
                    <p class="mb-0 text-gray">
                        Target khusus akan muncul di dashboard sesuai dengan
                        bulan aktif yang telah ditentukan.
                    </p>
                </div>
                <router-link to="/set-target/create" class="text-primary"
                    ><i class="fa fa-cog size-16 me-2"></i
                    ><u>Atur Ulang Target</u></router-link
                >
            </div>
            <hr />
            <div class="row">
                <div class="col-12">
                    <label
                        >Tahun Berlaku <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        readonly
                        v-model="validYear"
                        class="form-control mt-2"
                    />
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-center mt-2 mb-0">Januari</p>
                            <h5 class="text-center mt-2 mb-0">Rp 0</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                Februari
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                Maret
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                April
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                Mei
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                Juni
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                Juli
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                Agustus
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                September
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                Oktober
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                November
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center my-5">
                        <div class="d-flex flex-column align-items-center">
                            <p class="text-primary text-center mt-2 mb-0">
                                Desember
                            </p>
                            <h5 class="text-center text-primary mt-2 mb-0">
                                Rp 0
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Api from "../api/Api";

export default {
    data() {
        return {
            isLoad: false,
            validYear: null,
        };
    },
    created() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
hr {
    color: #c1cdd7;
}
</style>
