<template>
    <div class="padding-container">
        <div class="row justify-content-center mt-4">
            <div class="col-md-6">
                <div class="box p-0">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                        <li class="nav-item w-50" role="presentation">
                            <button
                                class="nav-link dashboard left w-100"
                                v-bind:class="{
                                    active: tab_type == 'process',
                                }"
                                @click="getTabValue('process')"
                            >
                                Dalam Proses ({{ list_process.total }})
                            </button>
                        </li>
                        <li class="nav-item w-50" role="presentation">
                            <button
                                class="nav-link dashboard right w-100"
                                v-bind:class="{
                                    active: tab_type == 'done',
                                }"
                                @click="getTabValue('done')"
                            >
                                Selesai ({{ list_done.total_pickup }})
                            </button>
                        </li>
                    </ul>
                    <div class="p-4">
                        <div class="tab-content" id="pills-tabContent">
                            <div
                                class="tab-pane fade"
                                v-bind:class="{
                                    'show active': tab_type == 'process',
                                }"
                            >
                                <template v-if="lists.length > 0">
                                    <div
                                        class="box mt-3 p-0"
                                        v-for="(item, index) in lists"
                                        :key="index"
                                    >
                                        <div class="header-card">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div
                                                    class="fw-bold capitalize d-flex align-items-center"
                                                >
                                                    <img
                                                        src="../../assets/truck.png"
                                                        width="18"
                                                        class="me-1"
                                                        alt=""
                                                    />
                                                    <template
                                                        v-if="
                                                            item.type ==
                                                            'pickup'
                                                        "
                                                    >
                                                        Jemput Barang
                                                    </template>
                                                    <template v-else>
                                                        {{ item.type }}
                                                    </template>
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        class="tag radius mobile pb-0"
                                                        v-bind:class="{
                                                            'yellow-cro':
                                                                item.status ==
                                                                'Menunggu Dijemput',
                                                            'yellow-cro-thin':
                                                                item.status ==
                                                                'Sedang Dijemput',
                                                            'blue-cro':
                                                                item.status ==
                                                                'Selesai Dijemput',
                                                            'green-cro':
                                                                item.status ==
                                                                'Off Loading',
                                                        }"
                                                    >
                                                        {{ item.status }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-4 pt-2 pb-2">
                                            <table
                                                class="w-100 ps-0 fw-bold"
                                                cellpadding="10"
                                            >
                                                <tr>
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div
                                                            class="d-flex justify-content-between"
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div
                                                                    class="me-2"
                                                                >
                                                                    <img
                                                                        src="../../assets/wa.png"
                                                                        width="16"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <a
                                                                        :href="
                                                                            `https://wa.me/` +
                                                                            item.phone
                                                                        "
                                                                        target="_blank"
                                                                        class="text-primary"
                                                                    >
                                                                        <u>
                                                                            +{{
                                                                                item.phone
                                                                            }}
                                                                        </u>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="d-flex align-items-center ms-auto"
                                                            >
                                                                <div
                                                                    class="me-1"
                                                                >
                                                                    <img
                                                                        src="../../assets/profile.png"
                                                                        width="16"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    {{
                                                                        item.customer
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="border-top">
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div class="d-flex">
                                                            <div class="me-1">
                                                                <img
                                                                    src="../../assets/location-dash-driver.svg"
                                                                    width="16"
                                                                    alt="location dash driver"
                                                                />
                                                            </div>
                                                            <div>
                                                                <div
                                                                    class="text-green fw-bold"
                                                                >
                                                                    {{
                                                                        item.origin_regency
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="text-primary"
                                                                >
                                                                    <u>
                                                                        {{
                                                                            item.origin_address
                                                                        }}
                                                                    </u>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="me-1">
                                                                <img
                                                                    src="../../assets/location.png"
                                                                    width="16"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <div
                                                                    class="text-green fw-bold"
                                                                >
                                                                    {{
                                                                        item.dest_regency
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-if="item.isShow">
                                                    <td
                                                        class="ps-0 pe-0 w-80 fw-normal"
                                                    >
                                                        <div class="box note">
                                                            <div
                                                                class="d-flex align-items-center justify-content-between border-bottom pb-2"
                                                            >
                                                                CRM Specialist
                                                                <div
                                                                    class="d-flex align-items-center"
                                                                >
                                                                    <img
                                                                        src="../../assets/specialist.svg"
                                                                        alt="specialist"
                                                                        class="me-1"
                                                                    />
                                                                    {{
                                                                        item.specialist_name
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="d-flex align-items-center mt-3"
                                                            >
                                                                <div
                                                                    class="me-2"
                                                                >
                                                                    <img
                                                                        src="../../assets/note-yellow.png"
                                                                        width="30"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div
                                                                        class="text-note fw-semibold"
                                                                    >
                                                                        {{
                                                                            item.note
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="fw-semibold mb-2 mt-2"
                                                        >
                                                            {{
                                                                item.service_type ===
                                                                "pengiriman_barang"
                                                                    ? "Informasi Barang"
                                                                    : "Informasi Motor"
                                                            }}
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-6"
                                                                v-if="
                                                                    item.service_type ===
                                                                    'pengiriman_motor'
                                                                "
                                                            >
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/moto.svg"
                                                                            width="18"
                                                                            alt="moto"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.vehicle
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-6"
                                                                v-if="
                                                                    item.service_type ===
                                                                    'pengiriman_motor'
                                                                "
                                                            >
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/cc.svg"
                                                                            width="18"
                                                                            alt="cc"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.cc ===
                                                                            "low"
                                                                                ? "100 CC - 149 CC"
                                                                                : item.cc ===
                                                                                  "middle"
                                                                                ? "150 CC - 249 CC"
                                                                                : "Diatas 250 CC"
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-8"
                                                                v-if="
                                                                    item.service_type ===
                                                                    'pengiriman_barang'
                                                                "
                                                            >
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/dimension.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.width ??
                                                                            0
                                                                        }}cm X
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.length ??
                                                                            0
                                                                        }}cm X
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.height ??
                                                                            0
                                                                        }}cm
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="d-flex mt-2"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/notes.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.description
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-4"
                                                                v-if="
                                                                    item.service_type ===
                                                                    'pengiriman_barang'
                                                                "
                                                            >
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/kg.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.weight
                                                                        }}
                                                                        Kg
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                            <div
                                                class="mb-2"
                                                v-if="item.isShow"
                                            >
                                                <button
                                                    class="btn btn-green btn-search w-100"
                                                    v-if="
                                                        item.status ==
                                                        'Menunggu Dijemput'
                                                    "
                                                    @click="
                                                        openModalOntheway(item)
                                                    "
                                                >
                                                    Menuju Lokasi
                                                </button>
                                                <button
                                                    class="btn btn-primary btn-search w-100"
                                                    v-if="
                                                        item.status ==
                                                        'Sedang Dijemput'
                                                    "
                                                    @click="
                                                        openModalPickup(item)
                                                    "
                                                >
                                                    Jemput Barang
                                                </button>
                                                <button
                                                    class="btn btn-outline-yellow w-100 mt-3"
                                                    @click="
                                                        openModalRequestNewDriver(
                                                            item
                                                        )
                                                    "
                                                >
                                                    Request Driver Baru
                                                </button>
                                            </div>
                                        </div>
                                        <div class="border-top">
                                            <div class="p-3 text-center">
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="showContent(index)"
                                                    class="text-dark"
                                                >
                                                    <div
                                                        class="d-flex align-items-center justify-content-center"
                                                    >
                                                        <template
                                                            v-if="
                                                                item.isShow ==
                                                                true
                                                            "
                                                        >
                                                            <div>
                                                                Lihat Lebih
                                                                Sedikit
                                                            </div>
                                                            <i
                                                                class="fa fa-angle-up size-20 ms-1"
                                                            ></i>
                                                        </template>
                                                        <template
                                                            v-if="
                                                                item.isShow ==
                                                                false
                                                            "
                                                        >
                                                            <div>
                                                                Lihat Lebih
                                                                Banyak
                                                            </div>
                                                            <i
                                                                class="fa fa-angle-down size-20 ms-1"
                                                            ></i>
                                                        </template>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center mt-3">
                                        <img
                                            src="../../assets/no-data-green.png"
                                            width="70"
                                            alt=""
                                        />
                                        <div class="mt-3 mb-4 text-gray">
                                            Tidak ada daftar orderan
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                class="tab-pane fade"
                                v-bind:class="{
                                    'show active': tab_type == 'done',
                                }"
                            >
                                <div
                                    class="d-flex mt-2 mb-2"
                                    v-if="dones.length > 0"
                                >
                                    <div class="ms-auto">
                                        <button
                                            class="btn btn-primary px-3"
                                            :disabled="
                                                list_done.is_done == false
                                            "
                                            @click="
                                                modalConfirmationOffLoad = true
                                            "
                                        >
                                            Off Loading Barang
                                        </button>
                                    </div>
                                </div>
                                <template v-if="dones.length > 0">
                                    <div
                                        class="box mt-3 p-0"
                                        v-for="(item, index) in dones"
                                        :key="index"
                                    >
                                        <div class="header-card">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div
                                                    class="fw-bold capitalize d-flex align-items-center"
                                                >
                                                    <img
                                                        src="../../assets/truck.png"
                                                        width="18"
                                                        class="me-1"
                                                        alt=""
                                                    />
                                                    <template
                                                        v-if="
                                                            item.type ==
                                                            'pickup'
                                                        "
                                                    >
                                                        Jemput Barang
                                                    </template>
                                                    <template v-else>
                                                        {{ item.type }}
                                                    </template>
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        class="tag radius mobile pb-0"
                                                        v-bind:class="{
                                                            'yellow-cro':
                                                                item.status ==
                                                                'Menunggu Dijemput',
                                                            'yellow-cro-thin':
                                                                item.status ==
                                                                'Sedang Dijemput',
                                                            'blue-cro':
                                                                item.status ==
                                                                'Selesai Dijemput',
                                                            'green-cro':
                                                                item.status ==
                                                                'Off Loading',
                                                        }"
                                                    >
                                                        {{ item.status }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-4 pt-2 pb-2">
                                            <table
                                                class="w-100 ps-0 fw-bold"
                                                cellpadding="10"
                                            >
                                                <tr>
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center justify-content-between"
                                                        >
                                                            <div>
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/wa.png"
                                                                            width="16"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <a
                                                                            :href="
                                                                                `https://wa.me/` +
                                                                                item.phone
                                                                            "
                                                                            target="_blank"
                                                                            class="text-primary"
                                                                        >
                                                                            <u>
                                                                                +{{
                                                                                    item.phone
                                                                                }}
                                                                            </u>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="d-flex align-items-center ms-auto"
                                                            >
                                                                <div
                                                                    class="me-1"
                                                                >
                                                                    <img
                                                                        src="../../assets/profile.png"
                                                                        width="16"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    {{
                                                                        item.customer
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="ps-0 w-80 fw-normal"
                                                    >
                                                        <div class="d-flex">
                                                            <div class="me-1">
                                                                <img
                                                                    src="../../assets/location-dash-driver.svg"
                                                                    width="16"
                                                                    alt="location dash driver"
                                                                />
                                                            </div>
                                                            <div>
                                                                <div
                                                                    class="text-green fw-bold"
                                                                >
                                                                    {{
                                                                        item.origin_regency
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="text-primary"
                                                                >
                                                                    <u>
                                                                        {{
                                                                            item.origin_address
                                                                        }}
                                                                    </u>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="me-1">
                                                                <img
                                                                    src="../../assets/location.png"
                                                                    width="16"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <div
                                                                    class="text-green fw-bold"
                                                                >
                                                                    {{
                                                                        item.dest_regency
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-if="item.isShow">
                                                    <td
                                                        class="ps-0 pe-0 w-80 fw-normal"
                                                    >
                                                        <div class="box note">
                                                            <div
                                                                class="d-flex align-items-center justify-content-between border-bottom pb-2"
                                                            >
                                                                CRM Specialist
                                                                <div
                                                                    class="d-flex align-items-center"
                                                                >
                                                                    <img
                                                                        src="../../assets/specialist.svg"
                                                                        alt="specialist"
                                                                        class="me-1"
                                                                    />
                                                                    {{
                                                                        item.specialist_name
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="d-flex align-items-center mt-3"
                                                            >
                                                                <div
                                                                    class="me-2"
                                                                >
                                                                    <img
                                                                        src="../../assets/note-yellow.png"
                                                                        width="30"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div
                                                                        class="text-note fw-semibold"
                                                                    >
                                                                        {{
                                                                            item.note
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="fw-semibold mb-2 mt-2"
                                                        >
                                                            {{
                                                                item.service_type ===
                                                                "pengiriman_barang"
                                                                    ? "Informasi Barang"
                                                                    : "Informasi Motor"
                                                            }}
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-6"
                                                                v-if="
                                                                    item.service_type ===
                                                                    'pengiriman_motor'
                                                                "
                                                            >
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/moto.svg"
                                                                            width="18"
                                                                            alt="moto"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.vehicle
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-6"
                                                                v-if="
                                                                    item.service_type ===
                                                                    'pengiriman_motor'
                                                                "
                                                            >
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/cc.svg"
                                                                            width="18"
                                                                            alt="cc"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.cc ===
                                                                            "low"
                                                                                ? "100 CC - 149 CC"
                                                                                : item.cc ===
                                                                                  "middle"
                                                                                ? "150 CC - 249 CC"
                                                                                : "Diatas 250 CC"
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-8"
                                                                v-if="
                                                                    item.service_type ===
                                                                    'pengiriman_barang'
                                                                "
                                                            >
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/dimension.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.width ??
                                                                            0
                                                                        }}cm X
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.length ??
                                                                            0
                                                                        }}cm X
                                                                        {{
                                                                            item
                                                                                ?.dimension
                                                                                ?.height ??
                                                                            0
                                                                        }}cm
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="d-flex mt-2"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/notes.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.description
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-4"
                                                                v-if="
                                                                    item.service_type ===
                                                                    'pengiriman_barang'
                                                                "
                                                            >
                                                                <div
                                                                    class="d-flex"
                                                                >
                                                                    <div
                                                                        class="me-2"
                                                                    >
                                                                        <img
                                                                            src="../../assets/kg.png"
                                                                            width="18"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {{
                                                                            item.weight
                                                                        }}
                                                                        Kg
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="border-top">
                                            <div class="p-3 text-center">
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        showContentDone(index)
                                                    "
                                                    class="text-dark"
                                                >
                                                    <div
                                                        class="d-flex align-items-center justify-content-center"
                                                    >
                                                        <template
                                                            v-if="
                                                                item.isShow ==
                                                                true
                                                            "
                                                        >
                                                            <div>
                                                                Lihat Lebih
                                                                Sedikit
                                                            </div>
                                                            <i
                                                                class="fa fa-angle-up size-20 ms-1"
                                                            ></i>
                                                        </template>
                                                        <template
                                                            v-if="
                                                                item.isShow ==
                                                                false
                                                            "
                                                        >
                                                            <div>
                                                                Lihat Lebih
                                                                Banyak
                                                            </div>
                                                            <i
                                                                class="fa fa-angle-down size-20 ms-1"
                                                            ></i>
                                                        </template>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center mt-3">
                                        <img
                                            src="../../assets/no-data-green.png"
                                            width="70"
                                            alt=""
                                        />
                                        <div class="mt-3 mb-4 text-gray">
                                            Tidak ada barang yang selesai
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalOnthewayConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalOnthewayConfirmation">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Segera Berangkat ke Lokasi Penjemputan
                    </h5>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalOnthewayConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-6 mt-4">
                        <button
                            @click="storeOntheway()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_store_ontheway"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store_ontheway"
                            ></span>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalPickupConfirmation">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm vsm-custom">
                <h5 class="fw-bold text-center">
                    Konfirmasi Penjemputan Barang
                </h5>
                <hr />
                <label>Foto Barang <span class="text-danger">*</span></label>
                <p class="text-gray">Untuk mempermudah proses oleh tim kami</p>
                <div
                    class="border-dashed d-flex justify-content-center py-5 cursor-pointer"
                    @click="triggerFileInput"
                    :class="{ 'd-none': uploadedImages.length !== 0 }"
                >
                    <input
                        type="file"
                        ref="fileInput"
                        @change="handleFileUpload"
                        accept="image/*"
                        style="display: none"
                    />
                    <img
                        src="../../assets/border-dashed.svg"
                        alt="border dashed"
                    />
                </div>
                <div
                    class="scroll-container"
                    :class="{ 'd-none': uploadedImages.length === 0 }"
                >
                    <div
                        v-if="uploadedImages.length < 4"
                        class="border-dashed border-photo-2 d-flex justify-content-center py-5 cursor-pointer"
                        @click="triggerFileInput"
                    >
                        <img
                            src="../../assets/border-dashed.svg"
                            alt="border dashed"
                        />
                    </div>
                    <div
                        v-for="(image, index) in uploadedImages"
                        :key="index"
                        class="border-photo"
                    >
                        <img
                            :src="image.preview"
                            alt="preview"
                            class="preview-image"
                        />
                        <img
                            src="../../assets/red-trash.svg"
                            alt="red-trashed"
                            class="trash-icon"
                            @click="removeImage(index)"
                        />
                    </div>
                </div>
                <p class="mt-3">
                    Foto Terupload ({{ uploadedImages.length }}/4)
                </p>
                <hr />
                <label class="mb-3"
                    >Remark<span class="text-danger">*</span></label
                >
                <textarea
                    placeholder="Tambahkan Remark"
                    class="form-control"
                    v-model="remark"
                ></textarea>
                <div class="row">
                    <div class="col-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="
                                (modalPickupConfirmation = false),
                                    (uploadedImages = []),
                                    (remark = '')
                            "
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-6 mt-4">
                        <button
                            @click="storePickup()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_store_pickup"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store_pickup"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmationOffLoad"></div>
            <div
                class="modal-body-chat vsm vsm-custom"
                v-if="modalConfirmationOffLoad"
            >
                <h5 class="fw-bold text-center">
                    Konfirmasi Off Loading Barang
                </h5>
                <hr />
                <label>Foto Barang <span class="text-danger">*</span></label>
                <p class="text-gray">Untuk mempermudah proses oleh tim kami</p>
                <div
                    class="border-dashed d-flex justify-content-center py-5 cursor-pointer"
                    @click="triggerFileInput"
                    :class="{ 'd-none': uploadedImages.length !== 0 }"
                >
                    <input
                        type="file"
                        ref="fileInput"
                        @change="handleFileUpload"
                        accept="image/*"
                        style="display: none"
                    />
                    <img
                        src="../../assets/border-dashed.svg"
                        alt="border dashed"
                    />
                </div>
                <div
                    class="scroll-container"
                    :class="{ 'd-none': uploadedImages.length === 0 }"
                >
                    <div
                        v-if="uploadedImages.length < 4"
                        class="border-dashed border-photo-2 d-flex justify-content-center py-5 cursor-pointer"
                        @click="triggerFileInput"
                    >
                        <img
                            src="../../assets/border-dashed.svg"
                            alt="border dashed"
                        />
                    </div>
                    <div
                        v-for="(image, index) in uploadedImages"
                        :key="index"
                        class="border-photo"
                    >
                        <img
                            :src="image.preview"
                            alt="preview"
                            class="preview-image"
                        />
                        <img
                            src="../../assets/red-trash.svg"
                            alt="red-trashed"
                            class="trash-icon"
                            @click="removeImage(index)"
                        />
                    </div>
                </div>
                <p class="mt-3">
                    Foto Terupload ({{ uploadedImages.length }}/4)
                </p>
                <hr />
                <label class="mb-3"
                    >Remark<span class="text-danger">*</span></label
                >
                <textarea
                    placeholder="Tambahkan Remark"
                    class="form-control"
                    v-model="remark"
                ></textarea>
                <div class="row">
                    <div class="col-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmationOffLoad = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-6 mt-4">
                        <button
                            @click="storeOffLoad()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_store_offload"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store_offload"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessOffload"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessOffload">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Proses Penugasan Driver Telah Selesai
                    </h5>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="closeOffload()"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalRequestNewDriver">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm vsm-custom">
                <div
                    class="d-flex align-items-center justify-content-between mb-4"
                >
                    <h5 class="fw-bold mb-0">Alasan Request Driver Baru</h5>
                    <img
                        src="../../assets/close-icon.svg"
                        alt="close icon"
                        @click="
                            (modalRequestNewDriver = false),
                                (reason = ''),
                                (anotherReason = '')
                        "
                        class="cursor-pointer"
                    />
                </div>
                <div class="border rounded p-3">
                    <label class="container-radio">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">
                                    Kapasitas Muatan Armada Sudah Penuh
                                </p>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="radio"
                            v-model="reason"
                            value="Kapasitas Muatan Armada Sudah Penuh"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-radio mt-4">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">
                                    Kendala Teknis pada Kendaraan
                                </p>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="radio"
                            v-model="reason"
                            value="Kendala Teknis pada Kendaraan"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-radio mt-4">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">
                                    Armada Mengalami Insiden Kecelakaan
                                </p>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="radio"
                            v-model="reason"
                            value="Armada Mengalami Insiden Kecelakaan"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-radio mt-4">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">
                                    Kondisi Cuaca Ekstrem
                                </p>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="radio"
                            v-model="reason"
                            value="Kondisi Cuaca Ekstrem"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-radio mt-4">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">
                                    Ketidaksesuaian Lokasi
                                </p>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="radio"
                            v-model="reason"
                            value="Ketidaksesuaian Lokasi"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-radio mt-4">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">
                                    Dokumen atau Informasi Tidak Lengkap
                                </p>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="radio"
                            v-model="reason"
                            value="Dokumen atau Informasi Tidak Lengkap"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-radio mt-4">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">
                                    Masalah Pribadi atau Kondisi Darurat
                                </p>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="radio"
                            v-model="reason"
                            value="Masalah Pribadi atau Kondisi Darurat"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-radio mt-4">
                        <div class="d-flex">
                            <div class="d-flex flex-column">
                                <p class="fw-bold mb-0">Alasan Lain</p>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="radio"
                            v-model="reason"
                            value="Alasan Lain"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <textarea
                        v-if="reason === 'Alasan Lain'"
                        placeholder="Tambahkan Detail Alasan"
                        class="form-control mt-4"
                        v-model="anotherReason"
                    ></textarea>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-yellow w-100 btn-lg"
                            @click="
                                (modalRequestNewDriver = false),
                                    (reason = ''),
                                    (anotherReason = '')
                            "
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="requestNewDriver()"
                            :disabled="isReason"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isReason"
                            ></span>
                            Request Baru
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";

export default {
    name: "pickupDriver",
    components: {},
    data() {
        return {
            moment: moment,
            list_process: {
                total: 0,
            },
            lists: [],
            modalOnthewayConfirmation: false,
            is_store_ontheway: false,
            delivery_assignation_id: null,
            modalPickupConfirmation: false,
            is_store_pickup: false,
            list_done: {
                total_pickup: 0,
                total_all: 0,
                is_done: null,
            },
            dones: [],
            tab_type: "process",
            is_store_offload: false,
            modalConfirmationOffLoad: false,
            modalSuccessOffload: false,
            uploadedImages: [],
            remark: "",
            modalRequestNewDriver: false,
            reason: "",
            anotherReason: "",
            isReason: false,
            delivery_assignation_id_driver: "",
        };
    },
    created() {
        this.getProcessLists();
        this.getDoneLists();
    },
    computed: {},
    methods: {
        triggerFileInput() {
            if (this.uploadedImages.length < 4) {
                this.$refs.fileInput.click();
            }
        },
        async handleFileUpload(event) {
            const file = event.target.files[0];
            if (!file) return;
            const preview = URL.createObjectURL(file);
            if (this.uploadedImages.length < 4) {
                try {
                    const url = await this.uploadAvatar(
                        this.uploadedImages.length,
                        file
                    );
                    this.uploadedImages.push({
                        file,
                        preview,
                        url,
                    });
                } catch (error) {
                    console.error("Upload failed:", error);
                }
            }
            event.target.value = "";
        },
        removeImage(index) {
            URL.revokeObjectURL(this.uploadedImages[index].preview);
            this.uploadedImages.splice(index, 1);
        },
        async uploadAvatar(index, file) {
            const formData = new FormData();
            formData.append("path", "delivery_assignation");
            formData.append("file", file);

            try {
                const response = await Api.post(
                    `${process.env.VUE_APP_SERVICE_URL}/general/upload`,
                    formData,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                return response.data.data;
            } catch (err) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: err.response.data.message,
                });
            }
        },
        getTabValue(value) {
            this.tab_type = value;
        },
        showContent(index) {
            this.lists.forEach((list, i) => {
                list.isShow = i === index ? !list.isShow : false;
            });
        },
        showContentDone(index) {
            this.dones.forEach((done, i) => {
                done.isShow = i === index ? !done.isShow : false;
            });
        },
        getProcessLists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/pickup/on_progress`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })

                .then((res) => {
                    this.list_process = res.data.data;
                    this.lists = res.data.data.list_data.map((item) => {
                        return {
                            ...item,
                            isShow: false,
                        };
                    });
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        getDoneLists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/pickup/done`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })

                .then((res) => {
                    this.list_done = res.data.data;
                    this.dones = res.data.data.list_data.map((item) => {
                        return {
                            ...item,
                            isShow: false,
                        };
                    });
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        openModalOntheway(item) {
            this.delivery_assignation_id = item.delivery_assignation_id;
            this.modalOnthewayConfirmation = true;
        },
        storeOntheway() {
            this.is_store_ontheway = true;
            var data = {
                delivery_assignation_id: this.delivery_assignation_id,
                status: "ontheway",
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/pickup/update`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.is_store_ontheway = false;
                    this.modalOnthewayConfirmation = false;
                    this.getProcessLists();
                    console.log(res);
                })
                .catch((err) => {
                    this.is_store_ontheway = false;
                    this.modalOnthewayConfirmation = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        openModalPickup(item) {
            this.delivery_assignation_id = item.delivery_assignation_id;
            this.modalPickupConfirmation = true;
        },
        openModalRequestNewDriver(item) {
            this.delivery_assignation_id_driver = item.delivery_assignation_id;
            this.modalRequestNewDriver = true;
        },
        storePickup() {
            this.is_store_pickup = true;
            var data = {
                delivery_assignation_id: this.delivery_assignation_id,
                status: "pickup",
                photos: this.uploadedImages.map((photo) => photo.url),
                remark: this.remark,
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/pickup/update`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then(() => {
                    this.is_store_pickup = false;
                    this.modalPickupConfirmation = false;
                    this.tab_type = "done";
                    this.uploadedImages = [];
                    this.remark = "";
                    this.getDoneLists();
                    this.getProcessLists();
                })
                .catch((err) => {
                    this.is_store_pickup = false;
                    this.modalPickupConfirmation = false;
                    this.uploadedImages = [];
                    this.remark = "";
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        storeOffLoad() {
            this.is_store_offload = true;
            var data = {
                photos: this.uploadedImages.map((photo) => photo.url),
                remark: this.remark,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/pickup/offload`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_store_offload = false;
                    this.modalConfirmationOffLoad = false;
                    this.modalSuccessOffload = true;
                    this.uploadedImages = [];
                    this.remark = "";
                })
                .catch((err) => {
                    this.is_store_offload = false;
                    this.modalConfirmationOffLoad = false;
                    this.uploadedImages = [];
                    this.remark = "";
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        closeOffload() {
            this.getDoneLists();
            this.getProcessLists();
            this.tab_type = "done";
            this.modalSuccessOffload = false;
        },
        requestNewDriver() {
            this.isReason = true;
            var data = {
                delivery_assignation_id: this.delivery_assignation_id_driver,
                reason:
                    this.reason === "Alasan Lain"
                        ? this.anotherReason
                        : this.reason,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/pickup/request_driver`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.modalRequestNewDriver = false;
                    this.isReason = false;
                    this.reason = "";
                    this.anotherReason = "";
                    this.getDoneLists();
                    this.getProcessLists();
                })
                .catch((err) => {
                    this.modalRequestNewDriver = false;
                    this.isReason = false;
                    this.reason = "";
                    this.anotherReason = "";
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0%;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #a3b4c2;
    transform: translate(0%, -50%);
}

.container-radio input:checked ~ .checkmark {
    background-color: transparent;
    border: 2px solid #329a5f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-radio input:checked ~ .checkmark:after {
    display: block;
}

.container-radio .checkmark:after {
    top: 2.6px;
    left: 2.6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #329a5f;
}
.border-photo-2 {
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
    border: 1px solid #8e8e94;
    height: 110px;
    width: 180px;
    object-fit: cover;
    border-radius: 8px;
}
.scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px;
    padding: 10px;
    .border-photo {
        position: relative;
        display: inline-block;
        flex: 0 0 auto;
        border: 1px solid #8e8e94;
        height: 110px;
        width: 180px;
        object-fit: cover;
        border-radius: 8px;
        img {
            border-radius: 8px;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .trash-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: auto;
            cursor: pointer;
        }
    }
}

textarea {
    resize: none;
}
.modal-vue .modal-body-chat.vsm.vsm-custom {
    height: 35rem;
    overflow-y: auto;
    width: 500px !important;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}
.border-dashed {
    border: 1px dashed #8e8e94;
    border-radius: 8px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background: none;
    border-color: #40bf76;
    border-bottom: 2px solid #40bf76;
    border-radius: 0px;
    color: #40bf76;
    font-weight: 600;
    padding: 10px;
}
.nav-pills .nav-link {
    border-radius: 0px;
    color: #a3b4c2;
    border-bottom: 1px solid #e6e6e8;
    padding: 10px;
}
.header-card {
    background: #f5f5f5;
    width: 100%;
    padding: 14px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.mobile {
    font-size: 11px;
}
.box.note {
    background: #fff2e5;
    border: unset;
    border-radius: 5px;
    padding: 10px;
}
.text-note {
    color: #cc6c00;
}
</style>
